/* eslint-disable no-bitwise */
import { remove, each } from 'lodash';
import ConsoleLogProvider from './console-log-provider';

export default class Logger {
    constructor() {
        this.logTypes = {
            FATAL: 1,
            ERROR: 2,
            WARN: 4,
            INFO: 8,
            DEBUG: 16,
            TRACE: 32,
            USAGE: 64,
        };

        this.logTypesReverse = {
            1: 'FATAL',
            2: 'ERROR',
            4: 'WARN',
            8: 'INFO',
            16: 'DEBUG',
            32: 'TRACE',
            64: 'USAGE',
        };

        // Note that the below use bitwise ORs
        this.logLevels = {
            NONE: 0,
            ALL:
                this.logTypes.FATAL |
                this.logTypes.ERROR |
                this.logTypes.WARN |
                this.logTypes.INFO |
                this.logTypes.DEBUG |
                this.logTypes.TRACE |
                this.logTypes.USAGE,
            DEFAULT: this.logTypes.FATAL | this.logTypes.ERROR,
        };
        this.logProviders = [];
        this.logLevel = 32;

        this.addLogProvider(new ConsoleLogProvider(), 'default', 'ALL');
        this.supports = {
            usageObject: true,
        };
    }

    addLogProvider(logProvider, logProviderId, logLevel) {
        this.removeLogProvider('default');
        const providerObject = {
            provider: logProvider,
            id: logProviderId,
            level: logLevel,
        };
        this.logProviders.push(providerObject);
    }

    removeLogProvider(logProviderId) {
        remove(this.logProviders, logProvider => logProvider.id === logProviderId);
    }

    log(severity, mwcId, message, stackTrace) {
        switch (this.logTypes[severity]) {
            case 1:
                this.fatal(mwcId, message, stackTrace);
                break;
            case 2:
                this.error(mwcId, message, stackTrace);
                break;
            case 4:
                this.warn(mwcId, message, stackTrace);
                break;
            case 8:
                this.info(mwcId, message, stackTrace);
                break;
            case 16:
                this.debug(mwcId, message, stackTrace);
                break;
            case 32:
                this.trace(mwcId, message, stackTrace);
                break;
            case 64:
                // mwcId, action, primaryValue or options object
                this.usage(mwcId, message, stackTrace);
                break;
            default:
                // Throw? or info?
                break;
        }
    }

    error(mwcId, message, stackTrace) {
        this.logInternal('error', mwcId, message, stackTrace);
    }

    fatal(mwcId, message, stackTrace) {
        this.logInternal('fatal', mwcId, message, stackTrace);
        // Do more?
    }

    warn(mwcId, message, stackTrace) {
        this.logInternal('warn', mwcId, message, stackTrace);
    }

    info(mwcId, message, stackTrace) {
        this.logInternal('info', mwcId, message, stackTrace);
    }

    debug(mwcId, message, stackTrace) {
        this.logInternal('debug', mwcId, message, stackTrace);
    }

    trace(mwcId, message, stackTrace) {
        this.logInternal('trace', mwcId, message, stackTrace);
    }

    usage(mwcId, action, primaryValue, secondaryValue, startTime, endTime, secIds, context) {
        // Supports either a single object parameter or a list of parameters
        let options = mwcId;
        if (typeof options === 'string') {
            options = {
                mwcId,
                action,
                primaryValue,
                secondaryValue,
                startTime,
                endTime,
                secIds,
                context,
            };
        }

        this.logInternal(
            'usage',
            options.mwcId,
            {
                mwcId: options.mwcId,
                // user-input action, like "update search filter"
                action: options.action,
                // the starting timestamp, useful for timing API responses
                startTime: options.startTime,
                // the completion timestamp, for timing API responses or other actions
                endTime: options.endTime,
                // metrics for this action
                properties: {
                    // tool name, e.g. mwc-sc-screener
                    tool: options.tool,
                    // component name, e.g. mwc-sc-filters
                    component: options.component,
                    // such as the name of a search filter
                    primaryValue: options.primaryValue,
                    // such as the value of a search filter
                    secondaryValue: options.secondaryValue,
                    // an array of security identifiers, if relevant to the action
                    secIds: options.secIds,
                    // an unstructured JSON document with additional metrics
                    context: options.context,
                },
            },
            null
        );
    }

    logInternal(severity, mwcId, message, stackTrace) {
        const self = this;
        each(this.logProviders, providerContainer => {
            const logLevel = typeof providerContainer.level;

            let parsedLogLevel = self.logLevel;

            if (logLevel === 'string') {
                parsedLogLevel = self.logLevels[providerContainer.level];
            }

            if (logLevel === 'number') {
                parsedLogLevel = providerContainer.level;
            }

            // Note that the below is a bitwise AND
            if (parsedLogLevel & self.logTypes[severity.toUpperCase()]) {
                providerContainer.provider.log(severity, mwcId, message, stackTrace);
            }
        });
    }

    getLogLevel(logLevel) {
        let result = this.logLevel;
        if (typeof logLevel === 'string') {
            if (this.logLevels[logLevel] !== undefined) {
                result = this.logLevels[logLevel];
            } else {
                result = this.logTypes[logLevel] || this.logLevels.ALL;
            }
        } else if (typeof logLevel === 'number') {
            result = logLevel;
        }

        return result;
    }
}
