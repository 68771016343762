import { find, findIndex, findKey, forEach, mergeWith } from 'lodash';

export default class DataManager {
    constructor() {
        this.dataSources = {};
        this.requests = {};
    }

    registerDataSource(properties) {
        /*
        properties: {
            dataSourceId,
            source,
            parameters,
            transformations
        } */
        const id = properties.dataSourceId;
        if (!Object.prototype.hasOwnProperty.call(this.dataSources, id)) {
            let { source } = properties;
            if (typeof source === 'undefined') {
                // we use the arrow syntax here to avoid having to bind the fetch function to window.mwc.dataAccess
                // (using bind makes it difficult to override if you want to use your own application's fetch method)
                source = options => {
                    return window.mwc.dataAccess.fetch(options).then(response => {
                        return response.text().then(text => {
                            let data = text;
                            if (data) {
                                try {
                                    // catch exceptions from parsing the response text as JSON
                                    data = JSON.parse(text);
                                } catch (exception) {
                                    window.mwc.logger.warn(null, exception.message, exception.stack);
                                }
                            }
                            // returns JSON if available, text if not
                            return response.ok ? data : Promise.reject(data);
                        });
                    });
                };
            }
            this.dataSources[id] = {
                source,
                parameters: properties.parameters,
                transformations: properties.transformations,
                subscribers: [],
            };
        }
    }

    updateDataSource(properties) {
        /*
        properties: {
            dataSourceId,
            parameters
        } */
        const id = properties.dataSourceId;
        const dataSource = this.dataSources[id];
        dataSource.parameters = properties.parameters;

        // Get the first subscribed component and use that as the ref for the usage tracking
        const subscriber = dataSource.subscribers[0] || {};
        this.fetchData(dataSource, id, subscriber.mwcId, subscriber.element);
    }

    fetchData(dataSource, dataSourceId, mwcId, element) {
        let transformedParameters = dataSource.parameters;
        // Store usageTrackingOptions in case it is removed by a transform
        const usageTrackingOptions = Object.assign(
            {},
            (dataSource.parameters && dataSource.parameters.usageTrackingOptions) || {}
        );
        if (dataSource.transformations && dataSource.transformations.length > 0) {
            transformedParameters = DataManager.transform(dataSource.transformations, {}, dataSource.parameters);
        }

        const request = dataSource.source(transformedParameters);
        const requestAborted = 'request aborted';

        if (dataSourceId) {
            if (this.requests[dataSourceId]) {
                // Abort any pending requests for this data source
                const pendingRequest = this.requests[dataSourceId];
                pendingRequest.requestStatus = requestAborted;
            }
            this.requests[dataSourceId] = request;
        }

        const requestStart = Date.now();
        request
            .then(data => {
                if (request.requestStatus === requestAborted) {
                    throw new Error(requestAborted);
                }

                if (window.mwc.logger.usage) {
                    DataManager.logDataLoadedEvent({
                        requestStart,
                        dataSourceId,
                        mwcId,
                        element,
                        tool: usageTrackingOptions.tool,
                        component: usageTrackingOptions.component,
                        primaryValue: usageTrackingOptions.primaryValue,
                        secondaryValue: usageTrackingOptions.secondaryValue,
                        parameters: transformedParameters,
                    });
                }

                if (this.requests[dataSourceId]) {
                    delete this.requests[dataSourceId];
                }
                dataSource.data = data;
                dataSource.fetchInProgress = false;
                DataManager.setSubscriberData(dataSource);
            })
            .catch(err => {
                dataSource.fetchInProgress = false;
                // Only log an error if we didn't abort the request ourselves
                if (err.message !== requestAborted) {
                    DataManager.callSubscriberErrorFunctions(dataSource, err);
                    window.mwc.logger.error(null, err);
                }
            });
    }

    subscribe(properties) {
        /*
        properties: {
            mwcId,
            element,
            modelName,
            dataSourceId,
            transformationPipeline,
            successFunction
            errorFunction
        } */
        const dataSource = this.dataSources[properties.dataSourceId];

        const subscriptionExists = find(dataSource.subscribers, { mwcId: properties.mwcId });
        if (!subscriptionExists) {
            dataSource.subscribers.push({
                mwcId: properties.mwcId,
                element: properties.element,
                modelName: properties.modelName,
                transformationPipeline: properties.transformationPipeline,
                successFunction: properties.successFunction,
                errorFunction: properties.errorFunction,
            });
        } else {
            // Make sure the element and functions are pointing to the latest reference
            if (subscriptionExists.element !== properties.element) {
                subscriptionExists.element = properties.element;
            }
            subscriptionExists.transformationPipeline = properties.transformationPipeline;
            subscriptionExists.successFunction = properties.successFunction;
            subscriptionExists.errorFunction = properties.errorFunction;
        }

        if (!dataSource.data && !dataSource.fetchInProgress) {
            dataSource.fetchInProgress = true;
            this.fetchData(dataSource, properties.dataSourceId, properties.mwcId, properties.element);
        } else if (dataSource.data) {
            DataManager.setSubscriberData(dataSource);
        }
    }

    subscribeComponent(mwcId, subscriptions, element) {
        forEach(subscriptions, subscription => {
            if (subscription && subscription.dataSourceId) {
                const subscribePropertyObject = {
                    mwcId,
                    element,
                    modelName: subscription.modelName,
                    dataSourceId: subscription.dataSourceId,
                    transformationPipeline: subscription.transformations,
                    successFunction: subscription.successFunction,
                    errorFunction: subscription.errorFunction,
                };
                this.subscribe(subscribePropertyObject);
            }
        });
    }

    unsubscribeComponent(mwcId, dataSourceId) {
        const unsubscribe = id => {
            const dataSource = this.dataSources[id];
            if (dataSource) {
                const index = findIndex(dataSource.subscribers, { mwcId });
                if (index > -1) {
                    dataSource.subscribers.splice(index, 1);
                }
            }
        };

        if (dataSourceId) {
            unsubscribe(dataSourceId);
        } else {
            Object.keys(this.dataSources).forEach(id => {
                unsubscribe(id);
            });
        }
    }

    isComponentSubscribed(dataSourceId, mwcId, element) {
        let subscribed = false;
        const dataSource = this.dataSources[dataSourceId];
        if (dataSource && dataSource.subscribers) {
            dataSource.subscribers.forEach(subscriber => {
                if (subscriber.mwcId === mwcId && subscriber.element === element) {
                    subscribed = true;
                }
            });
        }
        return subscribed;
    }

    static setSubscriberData(dataSource) {
        dataSource.subscribers.forEach(subscriber => {
            let modelWasSet = false;
            const subscriberData = DataManager.transform(
                subscriber.transformationPipeline || [],
                dataSource.data,
                dataSource.parameters
            );
            if (subscriber.element) {
                subscriber.element[subscriber.modelName] = subscriberData;
                modelWasSet = true;
            }
            if (subscriber.successFunction) {
                subscriber.successFunction({
                    modelWasSet,
                    modelName: subscriber.modelName,
                    modelData: subscriberData,
                });
            }
        });
    }

    static callSubscriberErrorFunctions(dataSource, err) {
        dataSource.subscribers.forEach(subscriber => {
            if (subscriber.errorFunction) {
                subscriber.errorFunction(err);
            }
        });
    }

    static transform(transformationPipeline, data, params) {
        transformationPipeline.forEach(transformation => {
            data = transformation(data, params);
        });
        return data;
    }

    static logDataLoadedEvent(options) {
        const { mwcId, element, dataSourceId, requestStart, parameters, component, secondaryValue } = options;

        const logMwcId = mwcId || 'mwcDataManager';
        const tagName = (element && element.tagName) || '';
        const tool = options.tool || tagName.toLowerCase() || logMwcId;
        const primaryValue = options.primaryValue || dataSourceId; // Let them override the primaryValue if they want to

        // Remove the authorization header
        const updatedParameters = mergeWith({}, parameters);
        let key = findKey(updatedParameters.headers || {}, (o, k) => k.toLowerCase() === 'authorization');
        if (key) {
            updatedParameters.headers[key] = 'REMOVED';
        }
        // Remove the body
        if (updatedParameters.body) {
            updatedParameters.body = 'REMOVED';
        }

        const context = {
            initiator: 'mwcDataManager',
            dataSourceId,
            parameters: updatedParameters,
        };

        window.mwc.logger.usage({
            mwcId: logMwcId,
            action: 'dataLoaded',
            tool,
            component,
            primaryValue,
            secondaryValue,
            startTime: requestStart,
            endTime: Date.now(),
            context,
        });
    }
}
