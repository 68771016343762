/* eslint-disable prettier/prettier */
export const STORAGE_STRATEGIES = {
    URL_PARAM: 'url_param',
    URL_HASH: 'url_hash',
    URL_HASHBANG: 'url_hashbang',
    LOCAL_STORAGE: 'local_storage',
    SESSION_STORAGE: 'session_storage',
};

async function setItemUrlParam(mwcId, key, stateObject) {
    const currentUrlString = window.location.href;
    const url = new URL(currentUrlString);
    url.searchParams.set(`${mwcId}-${key}`, JSON.stringify(stateObject));
    window.history.pushState(null, '', url.toString());
}

async function getItemUrlParam(mwcId, key) {
    const params = new URLSearchParams(window.location.search);
    return JSON.parse(params.get(`${mwcId}-${key}`));
}

async function setItemUrlHash(mwcId, key, stateObject) {
    const state = JSON.parse(decodeURI(window.location.hash.substr(1) || '{}'));
    state[`${mwcId}-${key}`] = stateObject;
    const stateString = JSON.stringify(state);
    window.history.pushState(null, '', `#${encodeURI(stateString)}`);
}

async function getItemUrlHash(mwcId, key) {
    const state = JSON.parse(decodeURI(window.location.hash.substr(1) || '{}'));
    return state[`${mwcId}-${key}`];
}

async function setItemUrlHashBang(mwcId, key, stateObject) {
    const state = JSON.parse(decodeURI(window.location.hash.substr(3) || '{}'));
    state[`${mwcId}-${key}`] = stateObject;
    const stateString = JSON.stringify(state);
    const url = new URL(
        `${window.location.origin}${window.location.pathname}${window.location.search}/#!/${encodeURI(stateString)}`
    );
    window.history.pushState(null, '', url.toString());
}

async function getItemUrlHashBang(mwcId, key) {
    const state = JSON.parse(decodeURI(window.location.hash.substr(3) || '{}'));
    return state[`${mwcId}-${key}`];
}

async function setItemLocalStorage(mwcId, key, stateObject) {
    window.localStorage.setItem(`${mwcId}-${key}`, JSON.stringify(stateObject));
}

async function getItemLocalStorage(mwcId, key) {
    return JSON.parse(window.localStorage.getItem(`${mwcId}-${key}`));
}

async function setItemSessionStorage(mwcId, key, stateObject) {
    window.sessionStorage.setItem(`${mwcId}-${key}`, JSON.stringify(stateObject));
}

async function getItemSessionStorage(mwcId, key) {
    return JSON.parse(window.sessionStorage.getItem(`${mwcId}-${key}`));
}

export default class StorageHelper {
    constructor(options = {}) {
        options.setFunctions = options.setFunctions || {};
        options.getFunctions = options.getFunctions || {};

        options.enabled = options.enabled !== false;

        this.enabledStrategies = !options.enabled
            ? {}
            : options.enabledStrategies || {
                  [STORAGE_STRATEGIES.URL_HASH]: true,
                  [STORAGE_STRATEGIES.URL_HASHBANG]: true,
                  [STORAGE_STRATEGIES.URL_PARAM]: true,
                  [STORAGE_STRATEGIES.LOCAL_STORAGE]: true,
                  [STORAGE_STRATEGIES.SESSION_STORAGE]: true,
              };

        this.fallbackStrategy = options.fallbackStrategy || STORAGE_STRATEGIES.SESSION_STORAGE;

        this.setFunctions = {
            [STORAGE_STRATEGIES.URL_HASH]: options.setFunctions[STORAGE_STRATEGIES.URL_HASH] || setItemUrlHash,
            [STORAGE_STRATEGIES.URL_HASHBANG]: options.setFunctions[STORAGE_STRATEGIES.URL_HASHBANG] || setItemUrlHashBang,
            [STORAGE_STRATEGIES.URL_PARAM]: options.setFunctions[STORAGE_STRATEGIES.URL_PARAM] || setItemUrlParam,
            [STORAGE_STRATEGIES.LOCAL_STORAGE]: options.setFunctions[STORAGE_STRATEGIES.LOCAL_STORAGE] || setItemLocalStorage,
            [STORAGE_STRATEGIES.SESSION_STORAGE]: options.setFunctions[STORAGE_STRATEGIES.SESSION_STORAGE] || setItemSessionStorage,
        };

        this.getFunctions = {
            [STORAGE_STRATEGIES.URL_HASH]: options.setFunctions[STORAGE_STRATEGIES.URL_HASH] || getItemUrlHash,
            [STORAGE_STRATEGIES.URL_HASHBANG]: options.setFunctions[STORAGE_STRATEGIES.URL_HASHBANG] || getItemUrlHashBang,
            [STORAGE_STRATEGIES.URL_PARAM]: options.getFunctions[STORAGE_STRATEGIES.URL_PARAM] || getItemUrlParam,
            [STORAGE_STRATEGIES.LOCAL_STORAGE]: options.getFunctions[STORAGE_STRATEGIES.LOCAL_STORAGE] || getItemLocalStorage,
            [STORAGE_STRATEGIES.SESSION_STORAGE]: options.getFunctions[STORAGE_STRATEGIES.SESSION_STORAGE] || getItemSessionStorage,
        };
    }

    setStorageStrategies(enabledStrategies) {
        this.enabledStrategies = enabledStrategies;
    }

    setFallbackStrategy(fallback) {
        this.fallbackStrategy = fallback;
    }

    setStrategyFunctions(strategy, setFunction, getFunction) {
        this.getFunctions[strategy] = getFunction;
        this.setFunctions[strategy] = setFunction;
    }

    disableAll() {
        this.enabledStrategies = {};
        this.fallbackStrategy = null;
    }

    async setItem(mwcId, key, stateObject = null, preferredStrategy = null) {
        if (this.enabledStrategies[preferredStrategy]) {
            return this.setFunctions[preferredStrategy](mwcId, key, stateObject);
        } else if (this.fallbackStrategy && this.enabledStrategies[this.fallbackStrategy]) {
            return this.setFunctions[this.fallbackStrategy](mwcId, key, stateObject);
        }
        throw new Error('Preferred strategy is not enabled and no fallback is configured.');
    }

    async getItem(mwcId, key, preferredStrategy) {
        if (this.enabledStrategies[preferredStrategy]) {
            return this.getFunctions[preferredStrategy](mwcId, key);
        } else if (this.fallbackStrategy && this.enabledStrategies[this.fallbackStrategy]) {
            return this.getFunctions[this.fallbackStrategy](mwcId, key);
        }
        return undefined;
    }
}
